@use '@/styles/utils/mixins.scss' as *;
.contactCardWrapper {
  background-color: $white;
  border-radius: 1.25rem;
  padding: 3.75rem 0rem;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
}
.contactCardContainer {
  display: flex;
  flex-direction: column;
  gap: 7.5rem;
  .contactCards {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    .contactCardsList {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2.5rem;

      @include tab() {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      @include mob() {
        grid-template-columns: repeat(1, 1fr);
      }

      .ContactCardWrapper {
        display: flex;
        flex-direction: column;
        gap: 1.875rem;
        border-right: none;

        @include mob() {
          &:not(:first-child) {
            position: relative;
            padding-top: 1.875rem;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 1px;
              background-color: $secondary;
            }
          }
        }

        &:not(:last-child) {
          border-right: 1px solid $secondary;

          @include mob() {
            border-right: none;
          }
        }

        .contactCardUpperContent {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          .contactCardsListName {
            color: $primary;
          }

          .contactCardsListTitle {
            color: $neutral08;
          }
        }

        .contactCardBottomContent {
          display: flex;
          flex-direction: column;
          gap: 0.875rem;
          .contantNumber {
            display: flex;
            align-items: center;
            gap: 0.413rem;

            .numberWrapper {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              .contactItem {
                padding-right: 0.5rem;
                border-right: 1px solid $primaryAlt;
                display: inline-block;
              }
              h6 {
                margin: 0;
                padding-left: 0.138rem;
                &:hover,
                &:focus,
                &:active {
                  color: $primary;
                }
              }
            }
          }

          .emailWrapper {
            display: flex;
            align-items: center;
            gap: 0.313rem;

            h6 {
              &:hover,
              &:focus,
              &:active {
                color: $primary;
              }
            }
          }
        }
      }
    }
    .contactCard {
      position: relative;
      padding: 1rem;
      .contactTitle {
        padding-bottom: 2rem;
        gap: 1.5rem;
      }
      p {
        display: inline-block;
        margin-right: 0.5rem;
        position: relative;
        &::after {
          content: '';
          margin-left: 0.5rem;
          border-right: 1px solid $primaryAlt;
        }
        &:last-child::after {
          display: none;
        }
      }
      .numberContainer {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        align-items: center;
      }
      .emailContainer {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        align-items: center;
        margin-top: 1.25rem;
      }
      &::after {
        content: '';
        position: absolute;
        background-color: $primaryAlt;
        height: 100%;
        width: 1px;
        right: -1.25rem;
        top: 0;
        @include mob() {
          height: 1px;
          width: 100%;
          bottom: -1.25rem;
          top: auto;
        }
      }
      &:nth-last-child(1)::after {
        display: none;
      }
    }
  }
  .companyDetails {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    & > div:first-child {
      @include mob() {
        display: none;
      }
    }
    .companyDetailsList {
      display: flex;
      flex-direction: row;
      gap: 2.5rem;
      justify-content: space-between;
      margin: 0 5rem;
      @include mob() {
        flex-direction: column;
        margin: 0;
      }
      .companyDetail {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        @include mob() {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}
